'use client'
import { MixerHorizontalIcon, PlusIcon } from '@radix-ui/react-icons'
import { css } from 'styled-system/css';
import useCategories from '@/hooks/useCategories';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { styled } from 'styled-system/jsx'
import Popover from '@/components/common/Popover';
import { Separator } from '@/components/common'
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { Category, isItemType } from 'src/types';
import { useForageItem } from '@planda/design-system';
import { useSearchParamActions } from '@/hooks/useSearchParamActions';
import { categoryColorDynamic } from '@/utils/categories';
import { cva } from 'styled-system/css';

const DAY_OPTIONS = [
    { name: 'Weekday', value: '1,2,3,4,5' }, { name: 'Weekend', value: '0,6' },
    { name: 'Monday', value: '1' }, { name: 'Tuesday', value: '2' }, { name: 'Wednesday', value: '3' }, { name: 'Thursday', value: '4' }, { name: 'Friday', value: '5' }, { name: 'Saturday', value: '6' }, { name: 'Sunday', value: '0' },
    // 'Holiday' !!! TODO: make function for detecting holiday
]
const DAY_COLOR = '#f2eca2'

const PRIORITY_COLOR = '#e2abc2'
const PRIORITY_OPTIONS = [
    { name: 'Important', value: '2' },
    { name: 'Normal', value: '1' },
    { name: 'Optional', value: '0' }
]

const Filter = () => {
    const { setSearchParams } = useSearchParamActions()
    const { item: types, set: setTypes } = useForageItem<string[]>('month-filter-types', (arr) => Array.isArray(arr) && arr.every(isItemType), [])

    // variable, reset on reload
    const [days, setDays] = useState<string[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const [sections, setSections] = useState<string[]>([])
    const [priorities, setPriorities] = useState<string[]>([])

    const [showTypes, setShowTypes] = useState(false)
    const [showSections, setShowSections] = useState(false)
    const [showCategories, setShowCategories] = useState(false)
    const [showDays, setShowDays] = useState(false)
    const [showPriorities, setShowPriorities] = useState(false)

    const [overflowTypes, setOverflowTypes] = useState(false)
    const [overflowSections, setOverflowSections] = useState(false)
    const [overflowCategories, setOverflowCategories] = useState(false)
    const [overflowDays, setOverflowDays] = useState(false)
    const [overflowPriorities, setOverflowPriorities] = useState(false)

    useEffect(() => {
        // const route = [
        //     ...(types.length > 0 ? ['types=' + types] : []),
        //     ...(categories.length > 0 ? ['categories=' + categories] : []),
        //     ...(sections.length > 0 ? ['sections=' + sections] : []),
        //     ...(days.length > 0 ? ['days=' + days] : []),
        // ]
        // console.log(router)
        // const url = new URL(`https://example.com?${oldParams}`);

        setSearchParams({
            'types': types,
            'categories': categories,
        })
        // if (types.length === 0) params.delete('types')
        // else params.set('types', types.join(','))
        // // console.log('url', url.search)
        // // console.log(`Query string (before):\t ${params}`);
        // if (categories.length > 0) params.set("categories", categories.join(','))
        // else params.delete('categories')

        // if (sections.length > 0) params.set("sections", sections.join(','))
        // else params.delete('sections')

        // if (days.length > 0) params.set("days", days.join(','))
        // else params.delete('days')

        // if (priorities.length === 0) params.delete('priorities')
        // else params.set('priorities', priorities.join(','))

        // console.log(`Query string (after):\t ${params}`);
        // // console.log('url', url.search)
        // console.log(router)
        // console.log((split.at(0) || '') + '?' + params.toString())
        // shallowReplace(params.toString())
        // router.replace((split.at(0) || '') + '?' + params.toString())
        // if (route.length > 0) {
        //     router.push('?' + route.join('&'), undefined, { shallow: true })
        // } else {
        //     // router.asPath
        //     router.replace('')
        // }
    }, [types, categories])

    const { sections: sectionOptions, categoryList: categoryOptions } = useCategories()

    function isOverflown(element: HTMLDivElement) {
        return element.scrollWidth > element.clientWidth;
    }

    return (
        <Popover
            trigger={(
                <Button aria-label="Update dimensions">
                    <label>Filter...</label> <MixerHorizontalIcon className={css({ marginLeft: 'auto', })} />
                </Button>
            )}
            className={css({ width: 260 })}
            align="start"
        >
            <Flex className={css({ flexDirection: 'column', gap: 10 })}>
                <Text bold>
                    Filter by:
                    {types.map((type,) => <SelectedBlob onClick={() => setTypes(p => p.filter(x => x !== type))} key={type} >{type}</SelectedBlob>)}
                    {sections.map(id => <SelectedBlob onClick={() => setSections(p => p.filter(x => x !== id))} key={id} >{sectionOptions?.find(x => x.id === id)?.name}</SelectedBlob>)}
                    {categories.map(path => {
                        const category = categoryOptions?.find((x: Category) => x.id === path)
                        return <SelectedBlob onClick={() => setCategories(p => p.filter(x => x !== path))} key={path} style={categoryColorDynamic(category?.colorNum)} >{category?.name}</SelectedBlob>
                    })}
                    {days.map(value => <SelectedBlob onClick={() => setDays(p => p.filter(x => x !== value))} key={value} style={{ background: DAY_COLOR }} >{DAY_OPTIONS.find(x => x.value === value)?.name}</SelectedBlob>)}
                    {priorities.map(value => <SelectedBlob onClick={() => setPriorities(p => p.filter(x => x !== value))} key={value} style={{ background: PRIORITY_COLOR }} >{PRIORITY_OPTIONS.find(x => x.value === value)?.name}</SelectedBlob>)}
                </Text>
                <Separator className={css({ opacity: 0.2, boxShadow: '$boxShadow' })} />
                <Flex>
                    Type:
                    <Group
                        ref={ref => setOverflowTypes(ref ? isOverflown(ref) : false)}
                        showAll={showTypes}
                        type="multiple"
                        value={types}
                        onValueChange={(value) => {
                            if (value) setTypes(value);
                        }}
                    >
                        <Blob value={'event'} className={css({ background: '$eventbg', color: '$eventtxt' })} >Event</Blob>
                        <Blob value={'task'} className={css({ background: '$taskbg', color: '$tasktxt' })} >Task</Blob>
                    </Group>
                    {(overflowTypes || showTypes) &&
                        <ShowMore  >
                            <TransparentGradient disabled={showTypes} />
                            <Plus onClick={() => setShowTypes(p => !p)} disabled={showTypes} />
                        </ShowMore>
                    }
                </Flex>
                {/* <Flex>
                    <label>Section:</label>
                    <Group
                        ref={ref => setOverflowSections(ref ? isOverflown(ref) : false)}
                        showAll={showSections}
                        type="multiple"
                        value={sections}
                        onValueChange={(value) => {
                            if (value) setSections(value);
                        }}
                    >
                        {sectionOptions?.map(({ name, id }) => <Blob key={id} value={id} >{name}</Blob>)}
                    </Group>
                    {(overflowSections || showSections) &&
                        <ShowMore>
                            <TransparentGradient disabled={showSections} />
                            <Plus onClick={() => setShowSections(p => !p)} disabled={showSections} />
                        </ShowMore>
                    }
                </Flex> */}
                <FilterOption urlName='sections' name="Section" setOverflow={setOverflowSections} show={showSections} setShow={setShowSections} overflow={overflowSections}
                    options={sectionOptions?.map(({ name, id }) => ({ name, value: id }))}
                    setValue={setSections} value={sections} />
                <Flex>
                    <label>Category:</label>
                    <Group
                        ref={ref => setOverflowCategories(ref ? isOverflown(ref) : false)}
                        showAll={showCategories}
                        type="multiple"
                        value={categories}
                        onValueChange={(value) => {
                            if (value) setCategories(value);
                        }}
                    >
                        {categoryOptions?.filter(Boolean).map(({ name, id, colorNum }) => <Blob key={id} value={id} style={categoryColorDynamic(colorNum)} >{name}</Blob>)}
                    </Group>
                    {(overflowCategories || showCategories) &&
                        <ShowMore >
                            <TransparentGradient disabled={showCategories} />
                            <Plus onClick={() => setShowCategories(p => !p)} disabled={showCategories} />
                        </ShowMore>
                    }
                </Flex>
                <FilterOption urlName='days' name="Day" style={{ background: DAY_COLOR }} setOverflow={setOverflowDays} show={showDays} setShow={setShowDays} overflow={overflowDays} options={DAY_OPTIONS} setValue={setDays} value={days} />
                <FilterOption urlName='priorities' name="Priority" style={{ background: PRIORITY_COLOR }} setOverflow={setOverflowPriorities} show={showPriorities} setShow={setShowPriorities} overflow={overflowPriorities} options={PRIORITY_OPTIONS} setValue={setPriorities} value={priorities} />
            </Flex>
        </Popover>
    )
}

export default Filter


const FilterOption = ({ name, urlName, style, setValue, value, show, setShow, overflow, options, setOverflow }: {
    name: string,
    style?: any,
    setOverflow: Dispatch<SetStateAction<boolean>>,
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    overflow: boolean,
    options?: { name: string, value: string }[],
    setValue: (value: string[]) => void,
    urlName: string,
    value: string[]
}) => {
    const { setSearchParams } = useSearchParamActions()
    function isOverflown(element: HTMLDivElement) {
        return element.scrollWidth > element.clientWidth;
    }

    useEffect(() => {
        setSearchParams({
            [urlName]: value
        })
    }, [setSearchParams, urlName, value])

    return (<Flex>
        <label>{name}:</label>
        <Group
            ref={ref => setOverflow(ref ? isOverflown(ref) : false)}
            showAll={show}
            type="multiple"
            value={value}
            onValueChange={(value) => {
                if (value) setValue(value);
            }}
        >
            {options?.map(x => <Blob key={x.name} value={x.value} style={style}>{x.name}</Blob>)}
        </Group>
        {(overflow || show) &&
            <ShowMore>
                <TransparentGradient disabled={show} />
                <Plus onClick={() => setShow(p => !p)} disabled={show} />
            </ShowMore>
        }
    </Flex>)
}


const Text = styled('div', {
    variants: {
        faded: {
            true: {
                opacity: 0.5
            }
        },
        bold: {
            true: {
                fontWeight: 500
            }
        }
    },
    base: {
        margin: 0,
        fontSize: 15,
        lineHeight: "19px"
    }
});
const Fieldset = styled('fieldset', {
    base: {
        all: "unset",
        display: "flex",
        gap: 20,
        alignItems: "center"
    }
});

const Button = styled('button', {
    base: {
        all: "unset",
        flexGeneral: "row",
        border: "$border",
        padding: "0.1rem 0.5rem",
        borderRadius: 0,
        minHeight: "2rem",
        "& label": {},
        "&:hover": {
            borderColor: "$outlineHover"
        },
        "&:focus": {
            boxShadow: "$focus"
        },
        "&[data-state=\"open\"]": {
            boxShadow: "$focus"
        }
    }
})


const TransparentGradient = styled('div', {
    variants: {
        disabled: {
            true: {
                display: "none"
            }
        }
    },
    base: {
        backgroundImage: "linear-gradient(to right, transparent, {colors.$gray1})",
        height: "100%",
        width: "2.5rem"
    }
})

const Plus = styled(PlusIcon, {
    variants: {
        disabled: {
            true: {
                paddingLeft: 0,
                opacity: 0.3,
                background: "transparent",
                height: "1rem"
            }
        }
    },
    base: {
        background: "$gray1",
        height: "stretch",
        paddingBlock: 2,
        paddingLeft: 5
    }
})

const ShowMore = styled('div', {
    base: {
        position: "absolute",
        right: 0,
        height: "100%",
        display: "flex",
        alignItems: "stretch"
    }
})



const Flex = styled('div', {
    base: {
        display: "flex",
        position: "relative",
        gap: 2
    }
});

export const blobStyle = cva({
    base: {
        display: 'inline-flex',
        alignItems: 'center',
        background: '$info4',
        color: '$hiContrast',
        borderRadius: 5,
        fontSize: '$1',
        padding: '1px 5px',
        margin: '2px',
        border: 'none',
        zIndex: 1,
        cursor: 'pointer',
        "&:hover": {
            scale: 1.05,
        }
    }
})

export const Tag = styled('div', blobStyle)

const SelectedBlob = styled('div', blobStyle)

const Blob = styled(ToggleGroup.Item, blobStyle)

const Group = styled(ToggleGroup.Root, {
    variants: {
        showAll: {
            true: {
                flexWrap: "wrap",
                whiteSpace: "normal"
            }
        }
    },
    base: {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }
})