import { styled } from 'styled-system/jsx'

export const IconButton = styled('button', {
    base: {
        all: 'unset',
        fontFamily: 'inherit',
        borderRadius: '100%',
        height: 25,
        width: 25,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '$primary11',
        position: 'absolute',
        top: 10,
        right: 10,
        '&:hover': {
            backgroundColor: "$primary4"
        },
        '&:focus': {
            boxShadow: "0 0 0 2px colors.$primary7"
        }
    }
});

export const GhostIconButton = styled('button', {
    variants: {
        size: {
            '1': {},
            '2': {
                borderRadius: '$2',
                height: '$6',
                width: "$6"
            },
            '3': {
                borderRadius: '$2',
                height: '$7',
                width: "$7"
            },
            '4': {
                borderRadius: '$3',
                height: '$8',
                width: "$8"
            }
        },
        inverted: {
            true: {
                '&::before': {
                    content: "",
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '$grayA4',
                    borderRadius: "inherit"
                }
            }
        },
        inverted2: {
            true: {
                backgroundColor: '$gray12',
                color: '$loContrast',
                _hover: {
                    backgroundColor: '$hiContrast',
                    '&::before': {
                        content: "",
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: "$grayDarkA6"
                    }
                }
            }
        }
    },
    base: {
        alignItems: 'center',
        appearance: 'none',
        boxSizing: 'border-box',
        display: 'inline-flex',
        flexShrink: 0,
        fontFamily: 'inherit',
        fontSize: '14px',
        justifyContent: 'center',
        lineHeight: '1',
        outline: 'none',
        padding: '0',
        textDecoration: 'none',
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        color: '$hiContrast',
        '&::before': {
            boxSizing: "border-box"
        },
        '&::after': {
            boxSizing: "border-box"
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: 'transparent',
            color: "$gray6"
        },
        borderRadius: '$1',
        height: '$5',
        width: '$5',
        backgroundColor: 'transparent',
        borderWidth: '0',
        position: 'relative',
        _hover: {
            backgroundColor: "$grayA3"
        },
        '&:focus': {
            boxShadow: "inset 0 0 0 1px colors.$grayA8, 0 0 0 1px colors.$grayA8"
        },
        '&:active': {
            backgroundColor: "$grayA4"
        },
        '&[data-radix-popover-trigger][data-state="open"], &[data-radix-dropdown-menu-trigger][data-state="open"]': {
            backgroundColor: "$grayA4"
        }
    }
});
