import { styled } from 'styled-system/jsx'
import loadingAnimation from 'public/lotties/main-loader.json'
import dynamic from 'next/dynamic';
// import Lottie from 'react-lottie-player';
const Lottie = dynamic(() => import('react-lottie-player'), {
    loading: () => <p>Loading...</p>,
    ssr: false
})

const BeanSpin = styled('div', {
    base: {
        width: '200px',
        height: '200px',
        display: 'inline-block',
        overflow: 'hidden',
        background: '#ffffff',
    }
});

const Thing = styled('div', {
    base: {
        width: '100%',
        height: '100%',
        position: 'relative',
        transform: 'translateZ(0) scale(1)',
        backfaceVisibility: 'hidden',
        transformOrigin: '0 0',
        '& div': {
            boxSizing: "content-box",
            "& :nth-child(1)": {
                display: "block",
                "& div": {
                    position: "absolute",
                    top: "92px",
                    left: "-8px",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    background: "#e15b64",
                    animation: "key3 1s linear infinite",
                    "& :nth-child(1)": {
                        animationDelay: "-0.67s"
                    },
                    "& :nth-child(2)": {
                        animationDelay: "-0.33s"
                    },
                    "& :nth-child(3)": {
                        animationDelay: "0s"
                    },
                },
            },
            "& :nth-child(2)": {
                transform: "translate(-15px,0)",
                "& div": {
                    position: "absolute",
                    top: "40px",
                    left: "40px",
                    width: "120px",
                    height: "60px",
                    borderRadius: "120px 120px 0 0",
                    background: "#f8b26a",
                    animation: "key1 1s linear infinite",
                    transformOrigin: "60px 60px",

                    "& :nth-child(2)": {
                        animation: "key2 1s linear infinite"
                    },
                    "& :nth-child(3)": {
                        transform: "rotate(-90deg)",
                        animation: "none"
                    },
                },
            },

        }
    }
})

//const converted = {
//   "@keyframes ldio-4lr5z5d7xej-1": {
//     "0%": { transform: "rotate(0deg)" },
//     "50%": { transform: "rotate(-45deg)" },
//     "100%": { transform: "rotate(0deg)" }
//   },
//   "@keyframes ldio-4lr5z5d7xej-2": {
//     "0%": { transform: "rotate(180deg)" },
//     "50%": { transform: "rotate(225deg)" },
//     "100%": { transform: "rotate(180deg)" }
//   },
// ".ldio-4lr5z5d7xej > div:nth-child(2)": { transform: "translate(-15px,0)" },
//   ".ldio-4lr5z5d7xej > div:nth-child(2) div": {
//     position: "absolute",
//     top: "40px",
//     left: "40px",
//     width: "120px",
//     height: "60px",
//     borderRadius: "120px 120px 0 0",
//     background: "#f8b26a",
//     animation: "ldio-4lr5z5d7xej-1 1s linear infinite",
//     transformOrigin: "60px 60px"
//   },
//   ".ldio-4lr5z5d7xej > div:nth-child(2) div:nth-child(2)": {
//     animation: "ldio-4lr5z5d7xej-2 1s linear infinite"
//   },
//   ".ldio-4lr5z5d7xej > div:nth-child(2) div:nth-child(3)": {
//     transform: "rotate(-90deg)",
//     animation: "none"
//   },
//   "@keyframes ldio-4lr5z5d7xej-3": {
//     "0%": { transform: "translate(190px,0)", opacity: 0 },
//     "20%": { opacity: 1 },
//     "100%": { transform: "translate(70px,0)", opacity: 1 }
//   },
//  ".ldio-4lr5z5d7xej > div:nth-child(1)": { display: "block" },
//   ".ldio-4lr5z5d7xej > div:nth-child(1) div": {
//     position: "absolute",
//     top: "92px",
//     left: "-8px",
//     width: "16px",
//     height: "16px",
//     borderRadius: "50%",
//     background: "#e15b64",
//     animation: "ldio-4lr5z5d7xej-3 1s linear infinite"
//   },
//   ".ldio-4lr5z5d7xej > div:nth-child(1) div:nth-child(1)": {
//     animationDelay: "-0.67s"
//   },
//   ".ldio-4lr5z5d7xej > div:nth-child(1) div:nth-child(2)": {
//     animationDelay: "-0.33s"
//   },
//   ".ldio-4lr5z5d7xej > div:nth-child(1) div:nth-child(3)": {
//     animationDelay: "0s"
//   },
//   ".loadingio-spinner-bean-eater-yri82zkoe47": {
//     width: "200px",
//     height: "200px",
//     display: "inline-block",
//     overflow: "hidden",
//     background: "#ffffff"
//   },
//   ".ldio-4lr5z5d7xej": {
//     width: "100%",
//     height: "100%",
//     position: "relative",
//     transform: "translateZ(0) scale(1)",
//     backfaceVisibility: "hidden",
//     transformOrigin: "0 0"
//   },
//  ".ldio-4lr5z5d7xej div": { boxSizing: "content-box" }
//}

const LoadLottie = styled(Lottie, {
    base: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        maxHeight: '100vh',
        maxWidth: '100vw',
        height: '70vh',
    }
})

const PageLoader = () => {
    return (
        // <div>
        <LoadLottie speed={1} play animationData={loadingAnimation} loop />
        // </div>
        // <BeanSpin>
        //     <Thing>
        //         <div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //         </div>
        //         <div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //         </div>
        //     </Thing>
        // </BeanSpin>
    )
}

export default PageLoader