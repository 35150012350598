import React from 'react';
import { styled } from 'styled-system/jsx'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

export interface RadioItem {
    id: string,
    value: string,
    label: string,
}

export interface RadioGroupProps {
    radioItems: RadioItem[], handleChange: ((value: string) => void), value: string
}

export const RadioGroup = ({ radioItems, handleChange, value }: RadioGroupProps) => (
    <form>
        <RadioGroupRoot value={value} onValueChange={handleChange} aria-label="Sort tasks by">
            {
                radioItems.map(item => {
                    return (
                        <Flex key={item.id} >
                            <RadioGroupRadio value={item.value} id={item.id}>
                                <RadioGroupIndicator />
                            </RadioGroupRadio>
                            <Label htmlFor={item.id}>{item.label}</Label>
                        </Flex>
                    )
                })
            }
        </RadioGroupRoot>
    </form>
);

export default RadioGroup;


const StyledRadio = styled(RadioGroupPrimitive.Item, {
    base: {
        all: "unset",
        backgroundColor: "$loContrast",
        width: 25,
        height: 25,
        borderRadius: "100%",
        boxShadow: "0 2px 10px {colors.$overlay3}",
        "&:hover": {
            backgroundColor: "$primary3"
        },
        "&:focus": {
            boxShadow: "0 0 0 2px black"
        }
    }
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
    base: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "relative",
        "&::after": {
            content: "\"\"",
            display: "block",
            width: 11,
            height: 11,
            borderRadius: "50%",
            backgroundColor: "$primary11"
        }
    }
});

// Exports
export const RadioGroupRoot = RadioGroupPrimitive.Root;
export const RadioGroupRadio = StyledRadio;
export const RadioGroupIndicator = StyledIndicator;

// Your app...
const Flex = styled('div', {
    base: {
        display: "flex",
        margin: "10px 0",
        alignItems: "center"
    }
});

const Label = styled('label', {
    base: {
        fontSize: 15,
        lineHeight: 1,
        userSelect: "none",
        paddingLeft: 15
    }
});