import { blobStyle } from '@/components/specific/Filter'
import React, { ReactNode } from 'react'
import { css } from 'styled-system/css'
import { styled } from 'styled-system/jsx'

export type QuickAddOption<T> = {
    label: string
    onClick: (date: T | null | undefined) => T
    unit: 'date' | 'time'
    hide?: boolean
}

const QuickAdd = <T,>({
    options,
    hide,
    children,
    quickModeAlign = 'bottom',
    onChange,
    value,
}: {
    options: QuickAddOption<T>[]
    hide?: boolean
    children: ReactNode
    quickModeAlign?: 'top' | 'bottom'
    onChange: ((x: T) => void) | undefined
    value: T | undefined
}) => {
    if (hide) return children
    const quickModeComponent = (
        <div className={css({ marginBlock: 3, display: 'flex' })}>
            {options.map(({ label, onClick: date }) => (
                <DateTag key={label} onClick={() => onChange?.(date(value))}>
                    {label}
                </DateTag>
            ))}
        </div>
    )
    return (
        <div className={css({ display: 'flex', flexDirection: 'column' })}>
            {quickModeAlign === 'top' && quickModeComponent}
            {children}
            {quickModeAlign === 'bottom' && quickModeComponent}
        </div>
    )
}

export default QuickAdd

export const Tag = styled('div', blobStyle)

const DateTag = styled(Tag, {
    base: {
        fontSize: '0.58em',
    },
})
