import useGetCategoryRes from '@/hooks/useGetCategoryRes'

/**
 * @returns everything from useGetCategoryRes, but with offline categoryUnits support
 */
export default function useActiveQuery() {
    const swrRes = useGetCategoryRes()

    // const [offlineCategories, setOfflineCategories] = useState<CategoryUnits | undefined>(undefined)

    // useEffect(() => {
    //     localforage.getItem('categories').then((categories) => {
    //         if (CategoryUnitsSchema.safeParse(categories).success) {
    //             setOfflineCategories(categories as CategoryUnits)
    //         }
    //     })
    // }, [])

    return {
        ...swrRes,
        categoryUnits: swrRes.data?.categoryUnits, // || offlineCategories,
    }
}
