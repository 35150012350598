'use client'
import { forwardRef } from "react";
import Link from "next/link";
import { merge } from "lodash";
import { styled } from "styled-system/jsx";
import { cva, cx, sva } from "styled-system/css"
import { RecipeVariantProps, SystemStyleObject } from "styled-system/types";
import { button as dsButton } from "@planda/styled-system/recipes";

export const LinkButton = styled(Link, dsButton)

const generalButtonStyles = {
  all: 'unset',
  borderRadius: 4,
  padding: '0.4em 0.8em',
  // border: '$border',

  backgroundColor: '$primary2',
  color: '$primary11',

  // buttonStyle: 'primary',
}

export const SmallButton = styled('button', {
  base: {
    textStyle: 'unimportant',
    backgroundColor: '$gray1',
    borderRadius: '1px 2px',
    borderWidth: 0.5,
    borderColor: '$gray7',
    cursor: 'pointer',
    padding: 2,
    borderStyle: 'groove',
    transition: 'width, transform 250ms ease-out',
  }
})

const staticButtonStyles = {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  color: '$text',
  background: '$gray1',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 8%',
  fontSize: 'inherit',
  fontWeight: 500,
  minHeight: '2.2rem',
  gap: '0.15rem',
  wordBreak: 'break-word',
  position: 'relative',
  boxShadow: `0 2px 10px {colors.$overlay3}`,
  cursor: 'pointer',

  '&:focus': {
    boxShadow: '$focus'
  },
} as const

export const transparentButton = cva({
  base: {
    ...staticButtonStyles,
    background: 'transparent',
    boxShadow: 'none',
    minHeight: 'max-content',
  }
})

export const TransparentButton = styled('button', transparentButton)

export const StaticButton = styled('button', {
  base: staticButtonStyles,

  variants: {
    size: {
      small: {
        fontSize: 12,
        padding: '0 10px',
        lineHeight: '25px',
        height: 25,
      },
    },
    hover: {
      shine: {
        '&::after': {
          content: "''",
          position: "absolute",
          top: "0",
          left: "0",
          width: "0",
          height: "100%",
          backgroundColor: "rgba(255,255,255,0.4)",
          WebkitTransition: "none",
          MozTransition: "none",
          transition: "none"
        },

        '&:hover': {
          '&::after': {
            width: "120%",
            backgroundColor: "rgba(255,255,255,0)",
            WebkitTransition: "all 0.4s ease-in-out",
            MozTransition: "all 0.4s ease-in-out",
            transition: "all 0.4s ease-in-out"
          }
        },
      }
    },
    variant: {
      transparent: {
        background: 'transparent',
        boxShadow: 'none',
        minHeight: 'max-content',
      },
      rectangle: {
        border: '$border',
        boxShadow: '$boxShadow',
        borderRadius: 0,
        textAlign: "center",
        background: "$gray1",
        flexGeneral: 'row',
        padding: '0 0.8rem',
        justifyContent: 'space-between',
        '&:hover': {
          borderColor: '$outlineHover'
        }
      }
    },
  },
});

export const blockButton = cva({
  base: {
    cursor: 'pointer',
    ...generalButtonStyles,
    borderRadius: 0,
    background: '$gray1',
    color: '$gray12',
    boxShadow: `inset 0 0 0 2px {colors.$gray12}`,
    '&:hover': { background: '$gray2', boxShadow: `inset 0 0 0 2px {colors.$gray12}` },
    '&:focus': { boxShadow: '$focus' },
  },
  variants: {
    isColored: {
      true: {
        borderRadius: 0,
        background: '$primary3',
        color: '$primary11',
        boxShadow: `inset 0 0 0 2px {colors.$primary9}`,
        '&:hover': { background: '$primary4', boxShadow: `inset 0 0 0 2px {colors.$primary9}`, },
        '&:focus': { boxShadow: '$focus' },
      }
    }
  }
})

export const BlockButtonLink = styled(Link,
  merge(blockButton, {
    base: { cursor: 'pointer', }
  })
)

interface ButtonProps2<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
}
type ButtonProps<T extends React.ElementType> = ButtonProps2<T> & ButtonVariants & { style?: Record<string, any>, containerCSS?: SystemStyleObject } & React.ButtonHTMLAttributes<HTMLButtonElement> & Omit<React.ComponentProps<T>, keyof ButtonProps2<T>>

// interface WithForwardRefType extends React.FC<ButtonProps<Option>>  {
//   <T extends Option>(props: ButtonProps<T>): ReturnType<React.FC<ButtonProps<T>>>
// }

// type ButtonProps<T extends React.ElementType> = ButtonProps2<T> & ButtonVariants & { style?: Record<string, any>, containerCSS?: SystemStyleObject } & React.ButtonHTMLAttributes<HTMLButtonElement> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps2<T>>

// export type Option<OptionValueType = unknown> {
//   value: OptionValueType;
//   label: string;
// }

// const Component = <T,>(props: ComponentProps<T>, ref: any) => {
//   const { data } = props

//   const foo = () => {
//     console.log(data)
//   }

//   return <div>{data.length}</div>
// }

const ButtonComponent = <T extends React.ElementType = 'button',>({ children, containerCSS, containerClassName, as, ...props }: ButtonProps<T>, forwardedRef?: any) => {
  // const { root: rawRoot } = button.raw({})
  const { root, shadow, edge, label } = button({
    isDisabled: props.disabled,
    ...props,
  })

  const LabelComp: React.ElementType = as || 'button'

  const ref = (forwardedRef && Object.keys(forwardedRef).length > 0) ? forwardedRef : undefined
  return (
    <div className={cx(root, containerClassName)} >
      {/* <ButtonShadow /> */}
      <span className={shadow} />
      <span className={edge} />
      <LabelComp {...props} data-label ref={ref}
        className={label}
        aria-disabled={props.disabled}
        tabIndex={props.disabled ? -1 : undefined}
      >{children}</LabelComp>
    </div>
  )
}

export const Button = forwardRef(ButtonComponent) as <T extends React.ElementType>(
  props: ButtonProps<T> & { ref?: any },
) => ReturnType<typeof ButtonComponent>

// export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, containerCSS, ...props }, forwardedRef) => {
//   return (
//     <ButtonWithCustomLabel containerCSS={containerCSS}>
//       <ButtonLabel isDisabled={props.disabled} {...props} ref={forwardedRef} >{children}</ButtonLabel>
//     </ButtonWithCustomLabel>
//   )
// })
// Button.displayName = 'Button';

// const commonButtonStyles = {
//   width: '18rem',
//   buttonStyle: 'secondary',
//   color: '$gray12',
// }
{/* <Button className={css({ backgroundColor: '$secondary4' })} style={{ ...commonButtonStyles }} color={'secondary4'}
    >
      <Link href="/list" className={css({ all: 'unset' })}>
        <NavLabel>Courses</NavLabel>
      </Link>
    </Button> */}

// ButtonLink.displayName = 'ButtonLink';


const buttonPart = {
  base: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  }
}

const buttonLabelStyles = {
  base: {
    ...generalButtonStyles,
    display: 'inline-flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    justifyContent: 'center',

    fontSize: '14px',
    padding: '12px 20px',
    background: '$primary2',
    transform: 'translateY(-1.5px)',
    width: '100%',
    userSelect: 'none',
    transition: 'transform 250ms ease-out',
    height: '100%',
    cursor: 'pointer',
    textAlign: 'center',
  },
}
// const ButtonLinkLabel = styled(Link, buttonLabelStyles)
// const HomeNavLabel = styled(Link, {
//   ...buttonLabelStyles,
//   width: '18rem',
//   buttonStyle: 'secondary',
//   color: '$gray12',
// })

// const ButtonWrapper = styled('div', {
//   base: {
//     position: 'relative',
//     borderRadius: 6,
//     border: 'none',
//     fontWeight: 600,
//     background: 'transparent',
//     padding: 0,
//     cursor: 'pointer',
//     transition: 'filter 250ms ease-out',
//     // width: 'max-content',
//     minHeight: 'max-content',
//     // height: 'auto',
//     // height: 'max-content',

//     '&:focus-within': {
//       boxShadow: '$focus'
//     },

//     '&:hover': {
//       filter: 'brightness(100%)',

//       [`& button`]: {
//         transform: 'translateY(-2.5px)',
//       },

//       [`& ${ButtonShadow}`]: {
//         transform: 'translateY(2px)',
//       },
//     },

//     '&:active': {
//       [`& button`]: {
//         transform: 'translateY(-1px)',
//         transition: 'transform 34ms',
//       },

//       [`& ${ButtonShadow}`]: {
//         transform: 'translateY(0.5px)',
//         transition: 'transform 34ms',
//       },
//     },
//   }
// })

const button = sva({
  className: 'btn',
  slots: ['root', 'shadow', 'edge', 'label'],
  base: {
    root: {
      position: 'relative',
      borderRadius: 6,
      border: 'none',
      fontWeight: 600,
      background: 'transparent',
      padding: 0,
      cursor: 'pointer',
      transition: 'filter 250ms ease-out',
      // width: 'max-content',
      minHeight: 'max-content',
      // height: 'auto',
      // height: 'max-content',

      '&:focus-within': {
        boxShadow: '$focus'
      },

      '&:hover': {
        filter: 'brightness(100%)',

        [`& [data-label]`]: {
          transform: 'translateY(-2.5px)',
        },

        [`& .btn__shadow`]: {
          transform: 'translateY(2px)',
        },
      },

      '&:active': {
        [`& [data-label]`]: {
          transform: 'translateY(-1px)',
          transition: 'transform 34ms',
        },

        [`& .btn__shadow`]: {
          transform: 'translateY(0.5px)',
          transition: 'transform 34ms',
        },
      },
    },
    shadow: {
      ...buttonPart,
      opacity: 0.1,
      background: 'hsl(0deg 0% 0% / 0.1)',
      transform: 'translateY(1px)',
      transition: 'transform 250ms ease-out',
    },
    edge: {
      ...buttonPart,
      background: `linear-gradient(
        to left,
        {colors.$gray8} 0%,
        {colors.$gray7} 8%,
        {colors.$gray7} 92%,
        {colors.$gray8} 100%
      )`,
    },
    label: buttonLabelStyles.base
  },
  variants: {
    color: {
      homeNav: {
        label: {
          width: '18rem',
          buttonStyle: 'secondary',
          color: '$gray12',
        }
      },
      white: {
        label: {
          backgroundColor: '$gray1',
          color: '$gray12',
          boxShadow: `0 2px 10px {colors.$overlay3}`,
        }
      },
      gray: {
        label: { buttonStyle: 'gray', }
      },
      contrast: {
        label: {
          backgroundColor: '$loContrast',
          color: '$gray12',
        }
      },
      error: {
        label: {
          buttonStyle: 'error',
        }
      },
      primary: {
        label: {
          buttonStyle: 'primary',
        }
      },
      primary4: {
        label: {
          buttonStyle: 'primary',
          backgroundColor: '$primary4',
        }
      },
      primary5: {
        label: {
          buttonStyle: 'primary',
          backgroundColor: '$primary5',
        }
      },
      primary6: {
        label: {
          buttonStyle: 'primary',
          backgroundColor: '$primary6',
        }
      },
      secondary: {
        label: {
          buttonStyle: 'secondary',
        }
      },
      secondary4: {
        label: {
          backgroundColor: '$secondary4',
          boxShadow: `inset 0 0 0 1px {colors.$secondary7}`,
        }
      },
      secondary5: {
        label: {
          backgroundColor: '$secondary5',
          boxShadow: `inset 0 0 0 1px {colors.$secondary7}`,
        }
      },
      secondary6: {
        label: {
          backgroundColor: '$secondary6',
          boxShadow: `inset 0 0 0 1px {colors.$secondary7}`,
        }
      },
      success: {
        label: {
          buttonStyle: 'success',
        }
      },
      blackAndWhite: {
        label: {
          backgroundColor: '$gray1',
          color: '$gray12',
          boxShadow: `inset 0 0 0 1px {colors.$gray12}`,
          '&:hover': { boxShadow: `inset 0 0 0 1px {colors.$primary12}` },
          '&:focus': { boxShadow: '$focus' },
        }
      },
      transparent: {
        label: {
          background: 'transparent',
          boxShadow: 'none',
          minHeight: 'max-content',
          [`& btn__edge`]: {
            backgroundColor: 'transparent'
          }
        }
      },
    },
    // size: {
    //   // TODO
    // },
    shape: {
      rectangle: {
        label: {
          borderRadius: 0, // TODO: style incomplete, untested + view rectangle variant in button in plandav2 for reference
        }
      }
    },
    isDisabled: {
      true: {
        label: {
          buttonStyle: 'gray',
          cursor: 'not-allowed',
          pointerEvents: 'none',
        }
      }
    }
  }
})

export type ButtonVariants = RecipeVariantProps<typeof button>


// export const Button = styled('button', {
//   borderRadius: 4,
//   borderColor: '$borderColor',
//   wordBreak: 'break-word',
//   position: 'relative',

//   '&:hover': {
//     transform: 'translateY(-1px)',
//   },

//   '&:active': {
//     transform: 'translateY(0.5px)',
//     transition: 'transform 34ms',
//   },

//   '&:after': {
//     height: 2,
//     width: '100%',
//     bottom: -2,
//     background: '$gray7',
//     opacity: 0.1,
//     transform: 'translateY(1px)',
//     transition: 'transform 250ms ease-out',
//   },

//   variants: {
//     color: {
//       gray: {
//         buttonStyle: 'gray',
//       },
//       primary: {
//         buttonStyle: 'primary',
//       },
//       success: {
//         buttonStyle: 'success',
//       },
//       blackAndWhite: {
//         backgroundColor: '$gray1',
//         color: '$gray12',
//         boxShadow: `inset 0 0 0 1px colors.$gray12`,
//         '&:hover': { boxShadow: `inset 0 0 0 1px colors.$primary12` },
//         '&:focus': { boxShadow: '$focus' },
//       }
//     },
//     size: {
//       // TODO
//     },
//   },

//   defaultVariants: {
//     color: 'primary'
//   }
// })