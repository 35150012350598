import { DateInput, TimeInput, DatePickerInput as DateRangePicker } from '@mantine/dates'
import React, { ComponentProps, forwardRef, useMemo } from 'react'
import { inputCSSVarStyle, inputLayoutStyle } from './Input'
import { changeDay } from '@planda/utils'
import { parseFromDateString } from '@/utils/date'
import { styled } from 'styled-system/jsx'
import { addDays, addWeeks, endOfDay } from 'date-fns'
import QuickAdd, { QuickAddOption } from '@/features/forms/item/components/QuickAdd'

const timeStringToDate = (time: string) => {
    if (!time) return null
    const [hour, minute] = time.split(':')
    const date = new Date()
    date.setSeconds(0)
    date.setHours(parseInt(hour))
    date.setMinutes(parseInt(minute))
    return date
}

const dateToTimeString = (date: Date | number) => {
    if (!date) return ''
    date = new Date(date)
    const hour = date.getHours()
    const minute = date.getMinutes()
    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
}

const QUICK_ADD_TIMES: QuickAddDate[] = [
    {
        label: '11:30am',
        onClick: (date: Date | null | undefined) => new Date((date || new Date()).setHours(11, 30, 0, 0)),
        unit: 'time',
    },
    {
        label: '7:00pm',
        onClick: (date: Date | null | undefined) => new Date((date || new Date()).setHours(19, 0, 0, 0)),
        unit: 'time',
    },
]

export const TimePicker = forwardRef<HTMLButtonElement, any>((props, ref) => {
    const { error, onChange, inputType, value, nilType, quickMode, quickAddOptions, ...field } = props
    const nil = nilType === 'undefined' ? undefined : null
    return (
        <QuickAdd hide={!quickMode} quickModeAlign="bottom" options={quickAddOptions || QUICK_ADD_TIMES} onChange={onChange} value={value}>
            <TimeInput
                value={dateToTimeString(value)}
                {...field}
                format="12"
                ref={ref}
                error={props.error}
                onChange={(e) => {
                    const date = timeStringToDate(e.target.value)
                    if (inputType === 'number') onChange(date?.getTime() ?? nil)
                    else onChange(date ?? nil)
                }}
                styles={() => ({
                    input: {
                        ...inputLayoutStyle,
                        ...inputCSSVarStyle,
                        minWidth: 88,
                    },
                })}
            />
        </QuickAdd>
    )
})
TimePicker.displayName = 'TimePicker'

const TagsHolder = styled('div', {
    // was Flex, {
    base: {
        display: 'flex',
        gap: 3,
    },
})

type QuickAddDate = QuickAddOption<Date>

const QUICK_ADD_DATES: QuickAddDate[] = [
    {
        label: 'today',
        onClick: (date: Date | null | undefined) => (date ? changeDay(date, Date.now()) : endOfDay(new Date())),
        unit: 'date',
    },
    {
        label: '5pm',
        onClick: (date: Date | null | undefined) => new Date((date || new Date()).setHours(17, 0, 0, 0)),
        unit: 'time',
    },
    // {
    //     label: '8am',
    //     date: (date: Date | null | undefined) => new Date((date || new Date()).setHours(8, 0, 0, 0))
    // },
    {
        label: '11:59pm',
        onClick: (date: Date | null | undefined) => endOfDay(date || new Date()),
        unit: 'time',
    },
    {
        label: 'add 3 days',
        onClick: (date: Date | null | undefined) => {
            return addDays(date || endOfDay(new Date()), 3)
        },
        unit: 'date',
    },
    {
        label: 'add a week',
        onClick: (date: Date | null | undefined) => endOfDay(addWeeks(date || new Date(), 1)),
        unit: 'date',
    },
]

type DatePickerProps = ComponentProps<typeof DateInput>
export const DatePicker = forwardRef<
    HTMLInputElement,
    {
        error?: string | any
        withTime?: boolean
        disablePopover?: boolean
        format?: 'short' | 'long'
        quickMode?: boolean
        customQuickAddDates?: QuickAddDate[]
        quickModeAlign?: 'top'
    } & DatePickerProps
>((props, ref) => {
    const { placeholder, format, withTime, quickMode, quickModeAlign = 'bottom', disablePopover = false, customQuickAddDates, ...field } = props
    // valueFormat="DD/MM/YYYY HH:mm:ss"
    const valueFormat = (format === 'short' ? 'MMM. D, YYYY' : 'MMMM D, YYYY') + (withTime ? ' h:mm A' : '')
    const defaultPlaceholder = disablePopover ? 'Type in a date' : 'Pick a date'
    const quickAddDates = (customQuickAddDates || QUICK_ADD_DATES).filter((x) => !x.hide)

    const quickAddDatesFiltered = useMemo(() => {
        return quickAddDates.filter((x) => withTime || x.unit === 'date')
    }, [withTime, quickAddDates])

    return (
        <QuickAdd onChange={field.onChange} value={field.value} hide={!quickMode} quickModeAlign={quickModeAlign} options={quickAddDatesFiltered}>
            <DateInput
                {...field}
                ref={ref}
                valueFormat={valueFormat}
                popoverProps={{
                    disabled: disablePopover,
                }}
                dateParser={parseFromDateString}
                styles={() => ({
                    ...(withTime && {
                        root: {
                            minWidth: 220,
                        },
                    }),
                    input: {
                        ...inputLayoutStyle,
                        ...inputCSSVarStyle,
                    },
                })}
                placeholder={placeholder || defaultPlaceholder}
            />
        </QuickAdd>
    )
})
DatePicker.displayName = 'DatePicker'

export const DateRangeInput = forwardRef<HTMLButtonElement, any>((props, ref) => {
    const { error, ...field } = props

    return (
        <DateRangePicker
            {...field}
            type="range"
            name="date"
            // value={[new Date(), new Date(2023, 8, 15)]}
            // format="12"
            // ref={ref}
            error={props.error}
            // valueFormat="MMM. D, YYYY"
            styles={() => ({
                input: {
                    ...inputLayoutStyle,
                    ...inputCSSVarStyle,
                },
            })}
        />
    )
})
DateRangeInput.displayName = 'DateRangeInput'

export const DateSeparateRangeInput = forwardRef<HTMLButtonElement, any>(({ start, end }, ref) => {
    return (
        <DateRangePicker
            type="range"
            value={[start.value, end.value]}
            onChange={(dates) => {
                start.onChange(dates[0])
                end.onChange(dates[1])
            }}
            // value={[new Date(), new Date(2023, 8, 15)]}
            // format="12"
            // ref={ref}
            error={start.error || end.error}
            // valueFormat="MMM. D, YYYY"
            styles={() => ({
                input: {
                    ...inputLayoutStyle,
                    ...inputCSSVarStyle,
                },
            })}
        />
    )
})
DateSeparateRangeInput.displayName = 'DateSeparateRangeInput'
