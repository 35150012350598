import { useGetCategoryResQuery } from '@/redux/features/api'
import { CategoryRes } from '@/types'

const useGetCategoryRes = () => {
    const categoryRes = useGetCategoryResQuery()

    return {
        ...categoryRes,
        data:
            categoryRes.data ||
            // @ts-ignore
            (categoryRes.error?.offline as CategoryRes),
    }
}

export default useGetCategoryRes
