'use client'
import { flatten, isNumber, range, xor } from 'lodash'
import { Category, Section, CategorySchema, SectionSchema, TodoGroupSchema } from 'src/types'
import useActiveQuery from './main/category/useActiveQuery'
import { TodoGroup } from 'src/types'
import {
    useGetGeneralThemeCustomizationsQuery,
    usePutCategoryUnitMutation,
} from '@/redux/features/api'
import { getSectionId } from '@/utils/category'
import { getInfoFromCategoryPath } from '@/utils/categories'
import { useParams } from 'next/navigation'
import { pathToCategoriesGlobal } from '@/utils/categories'
import { useCallback } from 'react'
import { MAX_CATEGORIES } from '@/constants/user'

export default function useCategories(path?: string) {
    const params = (useParams() || {}) as { category?: string[] }

    const category = path?.split('/') || params.category
    // const { category } = router.query as { category: string[] | undefined }
    const { categoryUnits, data, isLoading } = useActiveQuery()
    const [addCategoryUnit] = usePutCategoryUnitMutation()
    const { data: themeCustomization } = useGetGeneralThemeCustomizationsQuery()

    const currentCategoryUnits = category && pathToCategories(category as string[])
    const currentSection = currentCategoryUnits && (currentCategoryUnits?.length > 0 ? currentCategoryUnits[0] : undefined)
    const currentCategory = currentCategoryUnits && (currentCategoryUnits?.length > 1 ? currentCategoryUnits[1] : undefined)
    const currentGroup = currentCategoryUnits && (currentCategoryUnits?.length > 2 ? currentCategoryUnits[2] : undefined)
    const currentGroups = currentCategory && categoryUnits?.groups[currentCategory.id]

    // console.log('path', path)
    // console.log("currentGroup", currentGroup)
    const getCategoryNums = useCallback(
        (sectionId: string) => {
            // get next free color num
            const extraCategoryNums =
                themeCustomization?.customCategoryColors?.map((x, i) => (x ? i + MAX_CATEGORIES + 1 : -1)).filter((x) => x !== -1) || []
            const categories = categoryUnits?.categories[sectionId]
            const allowed = range(1, MAX_CATEGORIES + 1).concat(extraCategoryNums)

            let freeNums = allowed
            if (categories && categories.length > 0) {
                const unused = xor(
                    allowed,
                    categories.map((x) => x.colorNum)
                )
                if (unused.length > 0) freeNums = unused
            }
            freeNums.sort()
            return { freeNums, allCategoryNums: allowed }
        },
        [categoryUnits, themeCustomization]
    )

    /** helpers */
    function pathToCategories(path: string | string[]): [Section] | [Section, Category] | [Section, Category, TodoGroup] | undefined {
        return pathToCategoriesGlobal(path, categoryUnits)
    }

    const getNextFreeNum = (path: string) => {
        const freeNums = getCategoryNums(path).freeNums
        return freeNums[0]
    }

    /**
     * db- interactions
     */
    // // TODO: allow color selection from form?
    const putCategoryUnit = async (properties: Partial<Category> | Partial<Section>, path?: string) => {
        const { isSection, isCategory, isGroup } = getInfoFromCategoryPath(path)
        if (isCategory && !isNumber(properties.colorNum)) {
            properties.colorNum = getNextFreeNum(path!)
        }

        let initSchema = isSection ? SectionSchema : isCategory ? CategorySchema : TodoGroupSchema
        const schema = initSchema.partial() // Before it was .partial({active: true})
        const dbProperties = schema.strip().parse(properties)
        addCategoryUnit({ path, dbProperties })
    }

    return {
        pathToCategories,
        categoryUnits,
        sections: categoryUnits?.sections,
        categoriesDict: categoryUnits?.categories,
        categoryList: categoryUnits?.categories && (flatten(Object.values(categoryUnits?.categories)) as Category[]),
        groups: categoryUnits?.groups,
        putCategoryUnit,
        getCategoryNums,
        isLoading,
        getSectionId,
        currentCategory,
        currentSection,
        currentGroups,
        currentGroup,
        data,
        themeCustomization,
    }
}
