import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { cva } from 'styled-system/css';
import { styled } from 'styled-system/jsx'

export const separator = cva({
    base: {
        backgroundColor: "$outline",
        "&[data-orientation=horizontal]": {
            height: 1,
            width: "100%"
        },
        "&[data-orientation=vertical]": {
            height: "100%",
            width: 1
        }
    },
    variants: {
        light: {
            true: {
                backgroundColor: '$gray7'
            }
        }
    }
})

export const Separator = styled(SeparatorPrimitive.Root, separator)
