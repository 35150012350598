import { StarFilledIcon, StarIcon } from '@radix-ui/react-icons'
import React from 'react'
import { cva } from 'styled-system/css'
import { styled } from 'styled-system/jsx'

/**
 * clickable star, filled to unfilled (used for priority levels for tasks/events)
 * @returns A star that when clicked changes fill
 */

const starIconStyle = cva({ base: { color: '$warning7', } })
const PriorityHigh = styled(StarFilledIcon, starIconStyle)
const PriorityHighOutline = styled(StarIcon, starIconStyle)
const PriorityLow = styled(StarIcon, starIconStyle)

const Star = ({ value, onChange, size = 25, style, variant = 'fill', className }: { value: number, onChange?: (priority: number) => void, size?: number | string, variant?: 'outline' | 'fill', style?: Record<string, string | number>, className?: string }) => {
    const sizeStyles = { height: size, width: size }
    return value === 2 ? (variant === 'fill' ? <PriorityHigh className={className} style={{ ...style, ...sizeStyles }} onClick={() => onChange?.(1)} /> :
        <PriorityHighOutline className={className} style={{ ...style, ...sizeStyles }} onClick={() => onChange?.(1)} />
    ) : <PriorityLow className={className} style={{ ...style, ...sizeStyles }} onClick={() => onChange?.(2)} />
}

export default Star