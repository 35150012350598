import React from 'react'
import styles from './arrow.module.css'
import { css, cva } from 'styled-system/css'
import { SystemStyleObject } from 'styled-system/types'

const arrow = cva({
    base: {
        zIndex: 100,
    },
    variants: {
        direction: {
            left: {
                right: -75,
                left: 'auto',
                rotate: '180deg',
                scale: 0.5,
            },
            right: {
                left: -75,
            },
        },
    },
})

const innerArrow = cva({
    base: {
        position: 'absolute',
        top: '25px',
        width: '90%',
        height: '10px',
        backgroundColor: '$hiContrast',
        boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
        animation: '1s linear infinite arrow',
        '&::after, &::before': {
            content: "''",
            position: 'absolute',
            width: '60%',
            height: '10px',
            right: '-8px',
            backgroundColor: '$hiContrast',
        },
        '&::after': { top: '-12px', transform: 'rotate(45deg)' },
        '&::before': {
            top: '12px',
            boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
            transform: 'rotate(-45deg)',
        },
    },
})

const icon = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80px',
    height: '60px',
    boxSizing: 'border-box',
    /* aspect-ratio: 4/3, */
    /* height: 100%, */
    cursor: 'pointer',
    transformOrigin: 'center 0',
    scale: 0.8,
    zIndex: 100,
}

const Arrow = ({ style, direction, cssRaw }: { style?: any; cssRaw?: SystemStyleObject; direction?: 'left' | 'right' }) => {
    return (
        <div className={css(icon, arrow.raw({ direction }), cssRaw)} style={style}>
            <div className={innerArrow()}></div>
        </div>
    )
}

export default Arrow
