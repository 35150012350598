import { styled } from 'styled-system/jsx'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { CSSProperties, ReactNode } from 'react';

const StyledContent = styled(HoverCardPrimitive.Content, {
    base: {
        borderRadius: 6,
        padding: 20,
        zIndex: 100,
        maxWidth: 400,
        backgroundColor: '$loContrast',
        boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        '@media (prefers-reduced-motion: no-preference)': {
            animationDuration: '400ms',
            animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
            willChange: 'transform, opacity',
            '&[data-state="open"]': {
                '&[data-side="top"]': { animationName: 'slideDownAndFade' },
                '&[data-side="right"]': { animationName: 'slideLeftAndFade' },
                '&[data-side="bottom"]': { animationName: 'slideUpAndFade' },
                '&[data-side="left"]': { animationName: 'slideRightAndFade' },
            },
        },
    }
});

const StyledArrow = styled(HoverCardPrimitive.Arrow, { base: {
    fill: '$loContrast',
} });

function Content({ children, ...props }: any) {
    return (
        <HoverCardPrimitive.Portal>
            <StyledContent {...props}>
                {children}
                <StyledArrow />
            </StyledContent>
        </HoverCardPrimitive.Portal>
    );
}

// Exports
export const HoverCardWrapper = HoverCardPrimitive.Root;
export const HoverCardTrigger = HoverCardPrimitive.Trigger;
export const HoverCardContent = Content;

const HoverCard = ({ children, trigger, side, openDelay, style }: { children: ReactNode, trigger: JSX.Element, side?: "top" | "right" | "bottom" | "left", openDelay?: number, style?: CSSProperties }) => {
    return (
        <HoverCardWrapper openDelay={openDelay}>
            <HoverCardTrigger asChild>
                {trigger}
            </HoverCardTrigger>

            <Content side={side} sideOffset={5} style={style}>
                {children}
            </Content>
        </HoverCardWrapper>
    )
}

export default HoverCard