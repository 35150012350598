import React from 'react'
import { styled } from 'styled-system/jsx'

const Spinner = () => {
    return (
        <Outer><div></div><div></div><div></div></Outer>
    )
}

export default Spinner

const Outer = styled('div', {
    base: {
        display: "inline-block",
        position: "relative",
        width: "80px",
        height: "80px",
        '& div': {
            display: "inline-block",
            position: "absolute",
            left: "8px",
            width: "16px",
            background: "#fff",
            animation: `spinnerSpin 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite`,
            "&:nth-child(1)": { left: "8px", animationDelay: "-0.24s" },
            "&:nth-child(2)": { left: "32px", animationDelay: "-0.12s" },
            "&:nth-child(3)": { left: "56px", animationDelay: "0" },
        },
    }
})